<template>
  <div class="flex w-full flex-col-reverse md:flex-row">
    <div
      class="flex w-full flex-col justify-center text-left md:w-1/2 lg:w-7/12"
    >
      <h3 class="typo-h2 text-grey-900" v-html="props.headline" />
      <p
        v-if="props.description"
        class="typo-body-xl mt-5"
        v-html="props.description"
      />
      <div class="mt-8 flex flex-col lg:flex-row lg:items-center">
        <NuxtLink :to="{ name: props.button.to }">
          <BaseButton size="large" class="whitespace-nowrap">
            {{ props.button.text }}
          </BaseButton>
        </NuxtLink>
        <div class="typo-body-xl mt-6 lg:ml-6 lg:mt-0">
          {{ $t("httpErrors.base.or_if_you_need_help") }}
          <a :href="contactUsLink" class="font-medium text-brand-600">{{
            $t("httpErrors.base.contact_us")
          }}</a>
        </div>
      </div>
    </div>
    <div
      class="mb-2 flex w-full max-w-[230px] md:mb-0 md:ml-24 md:w-1/2 md:max-w-full lg:w-5/12"
    >
      <Person404 v-if="image === '404'" class="w-full" />
      <Person500 v-else class="w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/AuthStore";
const { t: $t } = useI18n();
const { isLoggedIn } = storeToRefs(useAuthStore());
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;

interface Props {
  headline: string;
  description?: string;
  button: {
    text: string;
    to: string;
  };
  image?: "404" | "500";
}
const Person404 = defineAsyncComponent(() => import("~/assets/404-person.svg"));
const Person500 = defineAsyncComponent(() => import("~/assets/500-person.svg"));
const props = withDefaults(defineProps<Props>(), {
  image: "404",
  description: "",
});

const contactUsLink = computed(() => {
  return isLoggedIn.value
    ? `${customerAppUrl}conversations/support`
    : "mailto:contact@officeguru.dk";
});
</script>

<style scoped>
:deep(em) {
  @apply text-semantic-notice-600 not-italic;
}
</style>
