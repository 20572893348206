<template>
  <RouteErrorHandlerBaseError
    :headline="$t('httpErrors.fallthrough.title')"
    :button="{ text: $t('httpErrors.base.go_to_home'), to: `/${lang}` }"
  />
</template>

<script setup lang="ts">
const lang = globalThis.lang;

interface Props {
  error: {
    url?: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description?: string;
    data?: any;
  };
}
const props = defineProps<Props>();

onMounted(() => {
  useBugsnag().notify(
    new Error(
      `Fallthrough error was shown. Check if additional error handling makes sense.`,
    ),
    (event) => {
      event.addMetadata("additional", {
        errors: props.error,
      });
    },
  );
});
</script>
