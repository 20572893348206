<template>
  <RouteErrorHandlerBaseError
    :headline="$t('httpErrors.unknown_product.title')"
    :description="description"
    :button="buttonOptions"
  />
</template>

<script setup lang="ts">
import { useCMSStore } from "~/store/CMSStore";
const { cmsValues } = useCMSStore();
const { t } = useI18n();

const router = useRouter();

const vendorPage = computed(() => {
  const pathItems = router.currentRoute.value.path.split("/");
  if (pathItems.length < 4) return false;
  const vendorPagePath = pathItems.slice(0, 4).join("/");
  if (!vendorPagePath) return false;

  if (router.resolve(vendorPagePath)?.name) {
    return router.resolve(vendorPagePath).name;
  }
});

const vendorProductListName = computed(() => {
  if (!vendorPage.value) return false;
  const pathItems = router.currentRoute.value.path.split("/");
  if (pathItems.length < 5) return false;
  const vendorProductListPath = pathItems.slice(0, 5).join("/");
  if (!vendorProductListPath) return false;

  // TODO: This does not work atm because we generate routes even though venders don't have products
  // Also, on this error page we don't know the vendors api id anymore (we lose the route meta), so we also
  // can not make a request to the API and see if there are products. This needs to be solved by not adding
  // routes for vendors that don't have products.
  // return router.resolve(vendorProductListPath).name;

  // Pretend there is no product list
  return "";
});

const description = computed(() => {
  if (!vendorPage.value) {
    return t("httpErrors.unknown_product.description_no_vendor");
  }
  if (!vendorProductListName.value) {
    return t("httpErrors.unknown_product.description_vendor_exists");
  }

  return t("httpErrors.unknown_product.description_vendor_exists_products");
});

const buttonOptions = computed(() => {
  if (vendorProductListName.value) {
    return {
      text: t("httpErrors.base.go_to_products"),
      to: (vendorProductListName.value as string) || "",
    };
  }
  if (vendorPage.value) {
    return {
      text: t("httpErrors.base.go_to_vendor"),
      to: (vendorPage.value as string) || "",
    };
  }
  return {
    text: t("httpErrors.base.go_to_services"),
    to: cmsValues?.link_all_services_page || "",
  };
});
</script>
