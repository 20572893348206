<template>
  <RouteErrorHandlerBaseError
    :headline="$t('httpErrors.unknown_product_overview.title')"
    :description="description"
    :button="{
      text: $t('httpErrors.base.go_to_services'),
      to: cmsValues?.link_all_services_page || '',
    }"
  />
</template>

<script setup lang="ts">
import { useCMSStore } from "~/store/CMSStore";
const { cmsValues } = useCMSStore();
const { t } = useI18n();
const router = useRouter();

const vendorExists = computed(() => {
  // In theory, when we can resolve this route, we know that the vendor exists (as there can't be a product overview
  // of a vendor w/o a vendor
  const routeName = router.currentRoute.value.name;
  if (!routeName) return false;
  // ...but better be safe than sorry
  const vendorRouteName = (routeName as string).replace("-products", "");
  return router.hasRoute(vendorRouteName);
});
const description = computed(() => {
  return vendorExists.value
    ? t("httpErrors.unknown_product_overview.description_vendor_exists")
    : t("httpErrors.unknown_product_overview.description_no_vendor");
});
</script>
