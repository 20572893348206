<template>
  <RouteErrorHandlerBaseError
    :headline="$t('httpErrors.unknown_service.title')"
    :description="$t('httpErrors.unknown_service.description')"
    :button="{
      text: $t('httpErrors.base.go_to_services'),
      to: cmsValues?.link_all_services_page || '',
    }"
  />
</template>

<script setup lang="ts">
import { useCMSStore } from "~/store/CMSStore";

const { cmsValues } = useCMSStore();
</script>
