<template>
  <main class="flex md:my-auto">
    <RouteErrorHandlerGeneric5xx v-if="isGeneric5xx" />
    <RouteErrorHandlerUnknownProductOverview v-else-if="isProductOverview" />
    <RouteErrorHandlerUnknownProduct v-else-if="isProduct" />
    <RouteErrorHandlerUnknownVendor v-else-if="isVendor" />
    <RouteErrorHandlerUnknownService v-else-if="isService" />
    <RouteErrorHandlerFallThrough v-else :error="error" />
  </main>
</template>

<script setup lang="ts">
interface Props {
  error: {
    url?: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description?: string;
    data?: any;
  };
}
const props = defineProps<Props>();

const { path } = useRoute();
const isVendor = computed(() => {
  return /^(\/da\/partnere\/|\/en\/vendors\/)[^/]+\/*$/.test(path);
});
const isProductOverview = computed(() => {
  return /^(\/da\/partnere|\/en\/vendors)\/[^/]+\/(produkter|products)\/*$/.test(
    path,
  );
});
const isProduct = computed(() => {
  return /^(\/da\/partnere|\/en\/vendors)\/[^/]+\/(produkter|products)\/[^/]+\/*$/.test(
    path,
  );
});
const isService = computed(() => {
  return /^(\/da\/services\/|\/en\/services\/)[^/]+\/*$/.test(path);
});
const isGeneric5xx = computed(() => {
  if (!props.error?.statusCode) return false;
  return props.error.statusCode.toString().charAt(0) === "5";
});

onMounted(() => {
  const code = props.error.statusCode.toString();
  if (code.charAt(0) !== "5" && code.charAt(0) !== "4") {
    useBugsnag().notify(
      new Error(
        `A ${code} (non 4xx nor 5xx) error occurred. Check if additional error handling is needed.`,
      ),
      (event) => {
        event.addMetadata("additional", {
          errors: props.error,
        });
      },
    );
  }
});
</script>
