<template>
  <RouteErrorHandlerBaseError
    :headline="$t('httpErrors.generic_500.title')"
    :description="$t('httpErrors.generic_500.description')"
    :button="{ text: $t('httpErrors.base.go_to_home'), to: `/${lang}` }"
    image="500"
  />
</template>

<script setup lang="ts">
const lang = globalThis.lang;
</script>
